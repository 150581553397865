<template>
  <div
    ref="el"
    :style="{
      '--x': `${elementX}px`,
      '--y': `${elementY}px`,
    }"
    :class="[ui.wrapper, to && ui.to]"
    v-bind="attrs"
  >
    <UCard :ui="ui">
      <slot v-if="$slots.default" />
    </UCard>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { twJoin } from 'tailwind-merge'
import { nuxtLinkProps } from '#ui/utils'
import colors from '#tailwind-config/theme/colors'
import type uiColors from '#ui-colors'
import type { card as cardConfig } from '#ui/ui.config'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  ...nuxtLinkProps,
  title: {
    type: String,
    default: undefined,
  },
  description: {
    type: String,
    default: undefined,
  },
  icon: {
    type: String,
    default: undefined,
  },
  color: {
    type: String as PropType<(typeof uiColors)[number]>,
    default: 'primary',
  },
  orientation: {
    type: String as PropType<'vertical' | 'horizontal'>,
    default: 'vertical',
  },
  class: {
    type: [String, Object, Array] as PropType<any>,
    default: undefined,
  },
  ui: {
    type: Object as PropType<Partial<typeof config.value & typeof cardConfig>>,
    default: () => ({}),
  },
})

const config = computed(() => {
  const base = twJoin(
    'rounded-xl',
    props.orientation === 'vertical' && 'w-full',
    !!slots.default &&
      props.orientation === 'horizontal' &&
      'grid lg:grid-cols-2 lg:items-center',
  )

  return {
    wrapper:
      'relative group isolate rounded-xl background-gradient ring-1 ring-gray-200 dark:ring-gray-700 before:hidden before:lg:block before:absolute before:-inset-[2px] before:h-[calc(100%+4px)] before:w-[calc(100%+4px)] before:z-[-1] before:rounded-[13px] flex-1 flex flex-col shadow',
    to: 'hover:ring-primary-500 dark:hover:ring-primary-400 transition-shadow duration-200',
    container: '',
    body: {
      base,
    },
    background:
      'bg-white dark:bg-gray-900 hover:bg-opacity-90 dark:hover:bg-opacity-90 transition-[background-opacity]',
    ring: '',
    rounded: 'rounded-xl',
    shadow: '',
  }
})

const el = ref<HTMLDivElement>()

const slots = useSlots()
const { elementX, elementY } = useSharedMouseInElement(el)
const { ui, attrs } = useUI(
  'landing.card',
  toRef(props, 'ui'),
  config,
  toRef(props, 'class'),
  true,
)

const colorLight = computed(() => {
  if (props.color === 'primary') {
    return '#ec4899'
  }
  // @ts-ignore
  return colors[props.color]?.['500'] || colors[props.color] || props.color
})
const colorDark = computed(() => {
  if (props.color === 'primary') {
    return '#ec4899'
  }
  // @ts-ignore
  return colors[props.color]?.['400'] || colors[props.color] || props.color
})
</script>

<style scoped>
.background-gradient::before {
  background: radial-gradient(
    250px circle at var(--x) var(--y),
    v-bind(colorLight) 0%,
    transparent 100%
  );
  will-change: background;
}

.dark {
  .background-gradient::before {
    background: radial-gradient(
      250px circle at var(--x) var(--y),
      v-bind(colorDark) 0%,
      transparent 100%
    );
  }
}
</style>
